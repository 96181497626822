import React from 'react';
import Lottie from 'lottie-react';
import loaderGabizSm from './gabiz-90x70.json';
import loaderGabizMd from './gabiz-170x140.json';
import loaderGabizLg from './gabiz-400x340.json';

const LoaderGabiz = ({ size }) => {
  let loader;
  let style;

  switch (size) {
    case 'sm':
      loader = loaderGabizSm;
      style = { width: '90px', height: '70px' };
      break;
    case 'md':
      loader = loaderGabizMd;
      style = { width: '170px', height: '140px' };
      break;
    case 'lg':
      loader = loaderGabizLg;
      style = { width: '400px', height: '340px' };
      break;
    default:
      loader = loaderGabizSm;
      style = { width: '90px', height: '70px' };
      break;
  }

  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 50,
  };

  return (
    <div style={containerStyle}>
      <div style={style}>
        <Lottie animationData={loader} />
      </div>
    </div>
  );
};

export default LoaderGabiz;
