import { useMediaQuery } from 'react-responsive';

export function useSizeMediaQuery() {
  const is640px = useMediaQuery({ maxWidth: 640 });
  const is768px = useMediaQuery({ maxWidth: 768 });
  const is1024px = useMediaQuery({ maxWidth: 1024 });
  const is1280px = useMediaQuery({ maxWidth: 1280 });
  const is1536px = useMediaQuery({ maxWidth: 1536 });

  if (is640px) return 'sm';
  if (is768px) return 'md';
  if (is1024px) return 'lg';
  if (is1280px) return 'lg';
  if (is1536px) return 'lg';
  return 'md';
}
