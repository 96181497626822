import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoaderGabiz from "./components/loader-gabiz";
import { useSizeMediaQuery } from "./components/sizeMediaQuery"


const App = () => {
  const { id } = useParams();
  const [message, setMessage] = useState("Redirecionando...");
  const [showLoader, setShowLoader] = useState(true);
  const [loaderSize] = useState(useSizeMediaQuery());

  useEffect(() => {
    debugger;
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.simplesnologia.biz/short-url/${id}`
        );
        if (response.data && response.data.originalUrl) {
          window.location.href = response.data.originalUrl;
        } else {
          setMessage("Link não encontrado");
        }
      } catch (error) {
        if (error?.response?.data?.message === "Short URL not found") {
          setShowLoader(false);
          setMessage("Link não encontrado");
        } else {
          console.error("Error fetching data:", error);
          setShowLoader(false);
          setMessage(
            "Erro ao buscar os dados. Por favor, tente novamente mais tarde."
          );
        }
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      {showLoader && <LoaderGabiz size={loaderSize} />}
      {!showLoader && <p>{message}</p>}
    </div>
  );
};

export default App;
